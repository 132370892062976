import React from "react"
import Img from "gatsby-image"
import styled, { createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import { FaLinkedin, FaArrowDown } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { Helmet } from 'react-helmet'

import ContactForm from "./../form.js"

import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';
var Scroll = require('react-scroll');
var Element = Scroll.Element;
var scroller = Scroll.scroller;

const GlobalStyle = createGlobalStyle`
    body {
        overflow-y:scroll;
        font-family: heebo, arial;

        @media screen and (max-width: 767px) {
            font-size:0.8em;
            
        }
    }

    p {
        font-size:1.2em;
        line-height:1.5em;
    }

    svg {
        vertical-align:-3px;
        margin-left:4px;
    }
`

const Box = styled.div`
    max-width:1000px;
    margin:0px auto;
    padding:0px 1.5em;
`;

const Top = styled(Box)`
    display:flex;
    flex-direction:row-reverse;
    margin-bottom:5em;
    @media screen and (max-width: 1075px) {
        display: block;
        margin-bottom:2em;
    }
`;

const Photo = styled.div`

    flex:0.4;    
    text-align:right; 
    padding-left:1.5em;

    & > div {
      display:inline-block;
      width:min(400px, 38vw); 
      padding-right:1em;
      z-index:1;
    }

    img {
        border-bottom-left-radius:5px;
        border-bottom-right-radius:5px;       
    }
    
    @media screen and (max-width: 1075px) {
        text-align:right;
        padding-left:0px;
        margin-bottom:-7em;
        & > div {
            width:280px;
        }
    }

    @media screen and (max-width: 565px) {
        text-align:center;
        padding-left:0px;
        margin-bottom:2em;

        & > div {
            margin-right:0em;
            width:280px;
        }
    }

`;

const About = styled.div`

    flex:0.6;
    
    p:first-of-type {    
        font-size:1.6em;
        margin:0px 0px 0.2em 0px;
        color: #000;
        font-weight:normal;
    }

    p:last-of-type {
        color:#333;
        margin-top:1em;
        line-height:2.2em;
        a, button {
            color:#333;
            text-decoration:none;
            background-color:#efeae5;
            padding:0em 0.2em;  
            margin:0em 0.1em;          
            border-radius:2px;
            border:none;
            line-height:1.5em;
            cursor:pointer;
        }

        button:focus {
            outline:0;
        }

        a:hover, button:hover {
            background-color:#e0d7d1;            
        }
    }

    & > div {
        margin:1.5em 0em 3.5em 0em;
    }

    h2 {      
        margin-top:0px;
        font-size:2.2em;
        font-weight:normal;
        line-height:1.4em;
        color:#000000;
        margin-bottom:0em;
    }
`;

const Page = styled.div`
    min-height:max(100vh, 330px); 
    position:relative;
    overflow-x:hidden;
    background-size: cover; 
    background-position:center;
    padding-bottom:2px;

    h2 {
        font-family: 'saira condensed', interstate;
        
        padding:0px 0.82em;
        margin:0px auto 0.3em auto;
        font-size:2.1em;
    }
    
    p {        
        margin:0px auto;
        padding:0px 1.5em;
    }

    p:not(:last-child) {
        margin-bottom:0.8em;
    }
`;

const PageVD = styled(Page)`
    min-height:max(100vh, 500px);
`;


const PageContact = styled(Page)`
    min-height:max(100vh, 600px);
`;

const VD = styled.div`
    height:max(58vh, 290px);
    position:relative;

    p, h2 {
        max-width:400px;        
    }

    div {
        width:100vw;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
`;


const SW = styled.div`
    height:calc(100vh - 2em);

    position:relative;

    p, h2 {
        max-width:600px;        
    }

    div {
        width:100vw;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    }
`;

const Contact = styled(SW)`
    
    height:max(calc(100vh - 2em), 560px);
    
    a, button {
        color:#333;
        text-decoration:none;
        background-color:#efeae5;
        padding:0em 0.2em;  
        margin:0em 0.1em;          
        border-radius:2px;
        border:none;
        line-height:1.5em;
        cursor:pointer;
    }

    button {
        padding:0.5em;
        margin-top:-4px;
        border-radius:4px;
    }

    p {
        line-height:2em;
    }

    button:focus {
        outline:0;
    }

    a:hover, button:hover {
        background-color:#e0d7d1;            
    }

`;
const Nav = styled.div` 
    position:absolute;
    bottom:1.5em;
    left:50%;
    transform: translateX(-50%);

    svg {
        margin:0px;
        vertical-align:-3px;
    }

    button {
        border:0px;
        outline:0px;
        background-color:transparent;
        color:inherit;
        padding:0px 2px;
        cursor:pointer;
    }

    @media screen and (max-width: 1023px) {
        font-size:1.25em;
    }

    @media screen and (max-width: 767px) {
        display:none;            
    }
`;

export default ({ data }) => {

    return (
        <>
            <Helmet>
                <title>JNVG - Freelance Mechatronics Engineering</title>
                <meta name="description" content="Freelance mechatronics engineer, specialized in model-based software design, vehicle dynamics and control systems." />
                <meta property='og:title' content='JNVG - Freelance Mechatronics Engineering' />
                <meta property='og:image' content={'https://jnvg.nl'+data.img3.childImageSharp.sizes.src} />
                <meta property='og:description' content='Freelance mechatronics engineer, specialized in model-based software design, vehicle dynamics and control systems.' />
                <meta property='og:url' content='https://jnvg.nl' />
            </Helmet>
            <Normalize />
            <GlobalStyle />   
            <Element name="Start"></Element>      
                <Top>
                    <Photo><Img fluid={data.img2.childImageSharp.fluid} alt="foto" /></Photo>            
                    <About>
                            <Img fixed={data.img1.childImageSharp.fixed} alt="JNVG" />

                            <p>Hi, I am Johannes van Ginkel,</p>
                            <h2><b>Freelance engineer</b>, specialized in <b>model-based</b> software design and <b>vehicle dynamics</b> controls.</h2>
                    <p>Contact me by <button onClick={() => {
                        scroller.scrollTo('Contact', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}>mail<MdMail /></button>, on <a href="https://www.linkedin.com/in/jnvanginkel/" rel="noreferrer" target="_blank">LinkedIn<FaLinkedin /></a> or 
                    read <button onClick={() => {
                            scroller.scrollTo('SW', {
                                duration: 800,
                                delay: 0,
                                smooth: true
                            });
                        }}>what I do<FaArrowDown /></button>.</p>
                    </About>                         
                </Top>

            <Element name="SW"></Element>

            <Page style={{ backgroundImage: `url(${data.img4.childImageSharp.sizes.src})`, color: '#fff' }}>
                <SW><div>
                    <h2>Software Development</h2>
                    <p>I am passionate about creating software that brings mechatronic systems to life and have the background to control actuators and deal with sensor inputs. My education is in mechatronics and controls engineering, so I come to software from a model-based perspective. This also means I am familiar with the V-model and know how to work within this paradigm.</p>
                    <p>
                        My main experience is with Matlab and Simulink, and I know how to generate high quality production code from these tools.
                        Next to that I can also code directly in C or C++, and have experience doing so for various embedded targets. 
                    </p>
                </div></SW>
                <Nav><button onClick={() => {
                    scroller.scrollTo('Start', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowLeft /></button> <button onClick={() => {
                    scroller.scrollTo('Start', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                    }}>○</button> <button onClick={() => {
                        scroller.scrollTo('SW', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}>●</button> <button onClick={() => {
                        scroller.scrollTo('VD', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}>○</button> <button onClick={() => {
                        scroller.scrollTo('CSD', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}>○</button> <button onClick={() => {
                        scroller.scrollTo('Contact', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}>○</button> <button onClick={() => {
                        scroller.scrollTo('VD', {
                            duration: 800,
                            delay: 0,
                            smooth: true
                        });
                    }}><FiArrowRight /></button></Nav>
            </Page>

            <Element name="VD"></Element>
            <PageVD style={{ backgroundImage: `url(${data.img3.childImageSharp.sizes.src})`}}>
                <VD><div>
                    <h2>Vehicle Dynamics Controls</h2>
                    <p>I am experienced in developing vehicle dynamics controls and state estimators for various driveline systems both in simulations and the real world.</p>
                    <p>I know how to debug and work with the CAN bus and various instrumentation systems. With over 30 weeks of vehicle testing under my belt and driving licenses for several proving grounds, I can also support in testing and calibration efforts.</p>
                </div></VD>
                <Nav><button onClick={() => {
                    scroller.scrollTo('SW', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowLeft /></button> <button onClick={() => {
                    scroller.scrollTo('Start', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('SW', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('VD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>●</button> <button onClick={() => {
                    scroller.scrollTo('CSD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('Contact', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('CSD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowRight /></button></Nav>
            </PageVD>

            <Element name="CSD"></Element>

            <Page style={{ backgroundImage: `url(${data.img5.childImageSharp.sizes.src})`, color: '#fff' }}>
                <SW><div>
                    <h2>Control Systems Design</h2>
                    <p>I solve controls problems in automotive applications but also for mechatronic systems and actuators in general. Where needed I will apply
                        state estimation techniques to observe values that can not be measured. I am experienced in developing plant models or using system identification to aid the development process.</p>
                    <p>
                        In my work I always combine a theoretical background with a pragmatic approach to come to solutions that work in the real world. Most of my past experience in this domain has 
                        been around vehicle dynamics, electro-mechanic actuators and the different components of electric powertrains.
                    </p>

                    
                </div></SW>
                <Nav><button onClick={() => {
                    scroller.scrollTo('VD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowLeft /></button> <button onClick={() => {
                    scroller.scrollTo('Start', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('SW', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('VD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('CSD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>●</button> <button onClick={() => {
                    scroller.scrollTo('Contact', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('Contact', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowRight /></button></Nav>
            </Page>

            <Element name="Contact"></Element>

            <PageContact>
                <Contact><div>
                    <h2>Contact me</h2>
                    <p>If you are interested in working together, or have any other questions: feel free to reach out to me using the form below or via <a href="https://www.linkedin.com/in/jnvanginkel/" rel="noreferrer" target="_blank">LinkedIn<FaLinkedin /></a>.</p>
                    <ContactForm />
                </div></Contact>
                <Nav><button onClick={() => {
                    scroller.scrollTo('CSD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}><FiArrowLeft /></button> <button onClick={() => {
                    scroller.scrollTo('Start', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('SW', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('VD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('CSD', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>○</button> <button onClick={() => {
                    scroller.scrollTo('Contact', {
                        duration: 800,
                        delay: 0,
                        smooth: true
                    });
                }}>●</button></Nav>
            </PageContact>
        </>
    );
}

/* <div style={{ width: 300, margin: 'calc(50vh - 57px) auto 0px auto', textAlign: 'right' }}>
    <Img fadeIn={false} fixed={data.img1.childImageSharp.fixed} alt="JNVG" />
    <b><a style={{ lineHeight: 3, fontFamily: 'arial', color: '#0000cc' }} href="https://www.linkedin.com/in/jnvanginkel/">LinkedIn</a></b>
</div> */

export const query = graphql`
{
    img1: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
            fixed(width: 180) {
              ...GatsbyImageSharpFixed_noBase64
            }
        }
    }

    img2: file(relativePath: { eq: "foto.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
        }
    }
    
    img3: file(relativePath: { eq: "vd.jpeg" }) {
        childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid_noBase64
            }
            sizes(maxWidth: 3840) {
              src  
            }
        }
    }

    img4: file(relativePath: { eq: "sw.jpeg" }) {
        childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid_noBase64
            }
            sizes(maxWidth: 3840) {
              src  
            }
        }
    }

    img5: file(relativePath: { eq: "csd.jpeg" }) {
        childImageSharp {
            fluid(maxWidth: 3840) {
              ...GatsbyImageSharpFluid_noBase64
            }
            sizes(maxWidth: 3840) {
              src  
            }
        }
    }
}
`